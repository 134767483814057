
async function getRawData() {
    //console.log("entering getPartnerData()");

    //let url_base = 'https://n7twoktopo7pgku5lbbxdop4ri0gtjkg.lambda-url.us-east-1.on.aws/';
    let url_base = `${process.env.VUE_APP_OPEN_URL}v1/partners/match/flat-table`;

    // console.log("url_base = ", url_base);
    // console.log("process.env.VUE_APP_ACX_MATCHING_KEY", process.env.VUE_APP_ACX_MATCHING_KEY);

    let result = await fetch(url_base, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-arenacx-matching-key': process.env.VUE_APP_ACX_MATCHING_KEY }
    })
        .then(response => {
            if (!response.ok) {
                console.error("HTTP error " + response.status);
                return null;
            }
            return response.json();
        })
        .catch(function (e) {
            console.log("error = ", e)
        });


    return result;
}

export async function getPartnerData() {
    let rawData = await getRawData();

    let result = null;

    result = rawData;

    return result;

}

export function getColumnStructure() {

    let columnData = [
        { "caption": "Partner Name", dataField: "bpo_name", allowHiding: false, fixed: true, fixedPosition: "left", dataType: "string" },

        { "caption": "Channels", dataField: "participating_in_channels", dataType: "string" },
        { "caption": "Partner Code", dataField: "account_code", dataType: "string" },

        {
            "caption": "Profile Data", visible: false, "columns": [
                { "caption": "QuickBooks ID", dataField: "quickbooks_vendor_id", visible: false, dataType: "number" },
                { "caption": "Website", dataField: "website", dataType: "string" },
                { "caption": "Primary Name", dataField: "primary_contact_name", dataType: "string" },
                { "caption": "Primary Title", dataField: "primary_contact_title", dataType: "string" },
                { "caption": "Primary Email", dataField: "primary_contact_email", dataType: "string" },
                { "caption": "Primary Phone", dataField: "primary_contact_phone", dataType: "string" },
                { "caption": "Escalation Name", dataField: "escalation_contact_name", dataType: "string" },
                { "caption": "Escalation Title", dataField: "escalation_contact_title", dataType: "string" },
                { "caption": "Escalation Email", dataField: "escalation_contact_email", dataType: "string" },
                { "caption": "Escalation Phone", dataField: "escalation_phone_number", dataType: "string" },
                { "caption": "Years in Business", dataField: "business_years", dataType: "number" },
                { "caption": "Company headquarters address", dataField: "locations", dataType: "object" },
            ]
        },

        {
            "caption": "Company Firmographics", "columns": [
                {
                    "caption": "Number of active agents",
                    "dataField": "number_active_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Number of sites",
                    "dataField": "number_sites",
                    "dataType": "number"
                },
                {
                    "caption": "Number of countries with operations",
                    "dataField": "number_countries",
                    "dataType": "number"
                },
                {
                    "caption": "Average Agent Tenure (years)",
                    "dataField": "agent_tenure",
                    "dataType": "number"
                },
                {
                    "caption": "Business model",
                    "dataField": "business_model",
                    "dataType": "string"
                },
                {
                    "caption": "Geographical type",
                    "dataField": "geo_type",
                    "dataType": "string"
                },
                {
                    "caption": "Industry/vertical Groups",
                    "dataField": "industries_verticals_groups",
                    "dataType": "string"
                },
                {
                    "caption": "Industries/verticals Specific",
                    "dataField": "industries_verticals",
                    "dataType": "string"
                },
                {
                    "caption": "Other industries/verticals",
                    "dataField": "other_industries_verticals",
                    "dataType": "string"
                },
                {
                    "caption": "Client Case study or reference",
                    "dataField": "industries_verticals_all",
                    "dataType": "string"
                },
                {
                    "caption": "Global Regions of Service",
                    "dataField": "countries_of_service_regions",
                    "dataType": "string"
                },
                {
                    "caption": "Countries of Service: Names",
                    "dataField": "countries_of_service",
                    "dataType": "string"
                },
                {
                    "caption": "Countries of Service: Codes",
                    "dataField": "countries_of_service_codes",
                    "dataType": "string"
                },
                {
                    "caption": "Countries of Service: Count",
                    "dataField": "countries_of_service_count",
                    "dataType": "string"
                }

            ]
        },

        {
            "caption": "HR Information", "columns": [

                {
                    "caption": "Minimum Team Size",
                    "dataField": "minimum_team_size",
                    "dataType": "number"
                },
                {
                    "caption": "Average Team Size",
                    "dataField": "average_team_size",
                    "dataType": "number"
                },
                {
                    "caption": "Maximum Team Size",
                    "dataField": "maximum_team_size",
                    "dataType": "number"
                },
                {
                    "caption": "Agents hired: 1 week",
                    "dataField": "agents_can_hire_1_week",
                    "dataType": "number"
                },
                {
                    "caption": "Agents hired: 2 weeks",
                    "dataField": "agents_can_hire_2_week",
                    "dataType": "number"
                },
                {
                    "caption": "Agents hired: 3 weeks",
                    "dataField": "agents_can_hire_3_week",
                    "dataType": "number"
                },
                {
                    "caption": "Agents hired: 1 month",
                    "dataField": "agents_can_hire_4_week",
                    "dataType": "number"
                },
                {
                    "caption": "Percent agents: full-time",
                    "dataField": "percent_full_time_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Percent agents: part-time",
                    "dataField": "percent_part_time_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Percent agents:  work remotely",
                    "dataField": "percent_remote_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Percent agents:  work on-site",
                    "dataField": "percent_center_based_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Percent agents: shared",
                    "dataField": "percent_shared_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Percent agents: dedicated",
                    "dataField": "percent_dedicated_agents",
                    "dataType": "number"
                },
                {
                    "caption": "Offer shared services",
                    "dataField": "shared_services",
                    "dataType": "boolean"
                }
            ]
        },

        {
            "caption": "Operations", "columns": [
                {
                    "caption": "Functions performed Groups",
                    "dataField": "functions_performed_groups",
                    "dataType": "string"
                },
                {
                    "caption": "Functions performed Specific",
                    "dataField": "functions_performed",
                    "dataType": "string"
                },
                {
                    "caption": "Geographies served",
                    "dataField": "geographies_served",
                    "dataType": "string"
                },
                {
                    "caption": "Skillsets provided",
                    "dataField": "skillset_provided",
                    "dataType": "string"
                },
                {
                    "caption": "Channels provided",
                    "dataField": "channel_provided",
                    "dataType": "string"
                },
                {
                    "caption": "Hours of operations",
                    "dataField": "hours_of_operation",
                    "dataType": "string"
                },
                {
                    "caption": "Other functions",
                    "dataField": "other_functions_performed",
                    "dataType": "string"
                },
                {
                    "caption": "24/7 operations?",
                    "dataField": "operations_24_7",
                    "dataType": "boolean"
                },
                {
                    "caption": "Languages served",
                    "dataField": "languages",
                    "dataType": "string"
                },
                {
                    "caption": "Language levels provided",
                    "dataField": "language_levels_provided",
                    "dataType": "object"
                }
            ]
        },

        {
            "caption": "Systems and Certifications", "columns": [
                {
                    "caption": "Internal CRM Systems",
                    "dataField": "internal_crm_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client CRM systems",
                    "dataField": "client_crm_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Data security certifications",
                    "dataField": "datasec_certifications",
                    "dataType": "string"
                },
                {
                    "caption": "Other certifications",
                    "dataField": "industry_other_certifications",
                    "dataType": "string"
                },
                {
                    "caption": "Other internal CRM Systems",
                    "dataField": "other_crm_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Other client CRM systems",
                    "dataField": "client_crm_systems_other",
                    "dataType": "string"
                }
            ]
        },

        {
            "caption": "Pricing Models and Guidance", "columns": [
                {
                    "caption": "Average hourly rate: non-technical services",
                    "dataField": "average_hourly_rate_non_tech",
                    "dataType": "number"
                },
                {
                    "caption": "Average hourly rate: tech tier 1 services",
                    "dataField": "average_hourly_rate_tech_t1",
                    "dataType": "number"
                },
                {
                    "caption": "Average hourly rate: tech tier 2 services",
                    "dataField": "average_hourly_rate_tech_t2",
                    "dataType": "number"
                },
                {
                    "caption": "Average hourly rate: tech tier 3 services",
                    "dataField": "average_hourly_rate_tech_t3",
                    "dataType": "number"
                },
                {
                    "caption": "Overtime multiplier",
                    "dataField": "overtime_multiplier",
                    "dataType": "number"
                },
                {
                    "caption": "Holiday multiplier",
                    "dataField": "observed_holiday_multiplier",
                    "dataType": "number"
                },
                {
                    "caption": "Percent Training discount",
                    "dataField": "training_discount_percent",
                    "dataType": "number"
                },
                {
                    "caption": "Pricing models",
                    "dataField": "pricing_models_available",
                    "dataType": "string"
                }
            ]
        },

        {
            "caption": "Industries and Verticals", "columns": [
                {
                    "caption": "#1 Vertical: percent of business",
                    "dataField": "industries_verticals_1_1",
                    "dataType": "string"
                },
                {
                    "caption": "#1 Vertical: amount of business",
                    "dataField": "industries_verticals_1_2",
                    "dataType": "number"
                },
                {
                    "caption": "#2 Vertical: percent of business",
                    "dataField": "industries_verticals_2_1",
                    "dataType": "string"
                },
                {
                    "caption": "#2 Vertical: amount of business",
                    "dataField": "industries_verticals_2_2",
                    "dataType": "number"
                },
                {
                    "caption": "#3 Vertical: percent of business",
                    "dataField": "industries_verticals_3_1",
                    "dataType": "string"
                },
                {
                    "caption": "#3 Vertical: amount of business",
                    "dataField": "industries_verticals_3_2",
                    "dataType": "number"
                },
                {
                    "caption": "Number of clients",
                    "dataField": "number_of_clients",
                    "dataType": "number"
                }
            ]
        },

        {
            "caption": "Staff Management", "columns": [
                {
                    "caption": "Leadership team tenure average (years)",
                    "dataField": "leadership_team_tenure",
                    "dataType": "string"
                },
                {
                    "caption": "Size of site (square feet)",
                    "dataField": "site_square_feet",
                    "dataType": "number"
                },
                {
                    "caption": "Size of agent workspace (square feet)",
                    "dataField": "site_square_feet_per_agent",
                    "dataType": "number"
                },
                {
                    "caption": "Open workstations / Available capacity",
                    "dataField": "workstation_unused_capacity",
                    "dataType": "number"
                },
                {
                    "caption": "Agents receive health insurance?",
                    "dataField": "agents_receive_health_insurance",
                    "dataType": "boolean"
                },
                {
                    "caption": "Annual Percentage agent turnover",
                    "dataField": "agent_turnover_percent",
                    "dataType": "number"
                },
                {
                    "caption": "How turnover calculated",
                    "dataField": "turnover_calculation",
                    "dataType": "string"
                },
                {
                    "caption": "Company culture.",
                    "dataField": "culture",
                    "dataType": "string"
                },
                {
                    "caption": "Employee retention/attrition/morale programs",
                    "dataField": "agent_retention",
                    "dataType": "string"
                },
                {
                    "caption": "Current client names",
                    "dataField": "current_customers",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Functions Performed", "columns": [
                {
                    "caption": "#1 Function",
                    "dataField": "functions_performed_1_1",
                    "dataType": "string"
                },
                {
                    "caption": "#1 Function: percent of business",
                    "dataField": "functions_performed_1_2",
                    "dataType": "number"
                },
                {
                    "caption": "#2 Function",
                    "dataField": "functions_performed_2_1",
                    "dataType": "string"
                },
                {
                    "caption": "#2 Function: percent of business",
                    "dataField": "functions_performed_2_2",
                    "dataType": "number"
                },
                {
                    "caption": "#3 Function",
                    "dataField": "functions_performed_3_1",
                    "dataType": "string"
                },
                {
                    "caption": "#3 Function: percent of business",
                    "dataField": "functions_performed_3_2",
                    "dataType": "number"
                },
                {
                    "caption": "Observed holidays",
                    "dataField": "observed_holidays",
                    "dataType": "File Upload"
                }
            ]
        },


        {
            "caption": "Reporting and Quality Management", "columns": [
                {
                    "caption": "Agents per QA admin",
                    "dataField": "average_agents_per_qa_admin",
                    "dataType": "number"
                },
                {
                    "caption": "Standard QA rubric?",
                    "dataField": "qa_rubric",
                    "dataType": "boolean"
                },
                {
                    "caption": "QA rubric (upload)",
                    "dataField": "quality_assurance_rubric",
                    "dataType": "File Upload"
                },
                {
                    "caption": "Dashboard APIs",
                    "dataField": "reporting_arenacx",
                    "dataType": "boolean"
                },
                {
                    "caption": "Real-time reporting dashboards?",
                    "dataField": "reporting",
                    "dataType": "boolean"
                },
                {
                    "caption": "Remote monitoring capabilities?",
                    "dataField": "qa_remote_monitoring",
                    "dataType": "boolean"
                },
                {
                    "caption": "Client dial-in ability?",
                    "dataField": "qa_remote_monitoring_clients",
                    "dataType": "boolean"
                },
                {
                    "caption": "Do you digitally record all calls?",
                    "dataField": "qa_call_recording",
                    "dataType": "boolean"
                },
                {
                    "caption": "If not, what percentage?",
                    "dataField": "qa_call_recording_percent",
                    "dataType": "number"
                },
                {
                    "caption": "Ad hoc reporting capabilities",
                    "dataField": "reporting_customized",
                    "dataType": "string"
                },
                {
                    "caption": "Agent feedback & monitoring",
                    "dataField": "qa_agent_feedback",
                    "dataType": "string"
                },
                {
                    "caption": "QA processes",
                    "dataField": "qa_process",
                    "dataType": "string"
                },
                {
                    "caption": "Call monitoring process",
                    "dataField": "qa_call_monitoring_process",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Workflow Management", "columns": [
                {
                    "caption": "ProjMgmt: Dedicated launch PM",
                    "dataField": "projmgmt_dedicated_launch_pm",
                    "dataType": "boolean"
                },
                {
                    "caption": "ProjMgmt: Standard template",
                    "dataField": "projmgmt_standard_template",
                    "dataType": "boolean"
                },
                {
                    "caption": "ProjMgmt: Template attached",
                    "dataField": "projmgmt_template",
                    "dataType": "string"
                },
                {
                    "caption": "WFM: Demand planning tool",
                    "dataField": "wfm_demand_planning_tool",
                    "dataType": "string"
                },
                {
                    "caption": "WFM: capacity planning tool",
                    "dataField": "wfm_capacity_planning_tool",
                    "dataType": "string"
                },
                {
                    "caption": "WFM: Process description",
                    "dataField": "wfm_process_description",
                    "dataType": "string"
                },
                {
                    "caption": "Implementation process and timeline",
                    "dataField": "projmgmt_process",
                    "dataType": "string"
                },
                {
                    "caption": "Change management process (WFM)",
                    "dataField": "change_process",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Work Volume", "columns": [
                {
                    "caption": "Annual interactions: Phone",
                    "dataField": "annual_interactions_per_demand_phone",
                    "dataType": "number"
                },
                {
                    "caption": "Annual interactions: Chat",
                    "dataField": "annual_interactions_per_demand_chat",
                    "dataType": "number"
                },
                {
                    "caption": "Annual interactions: Email/Webform",
                    "dataField": "annual_interactions_per_demand_email_webform",
                    "dataType": "number"
                },
                {
                    "caption": "Annual interactions: Social media",
                    "dataField": "annual_interactions_per_demand_social",
                    "dataType": "number"
                },
                {
                    "caption": "Annual interactions: SMS/Text",
                    "dataField": "annual_interactions_per_demand_sms",
                    "dataType": "number"
                },
                {
                    "caption": "Annual interactions: Video",
                    "dataField": "annual_interactions_per_demand_video",
                    "dataType": "number"
                },
                {
                    "caption": "Annual interactions: All other channels",
                    "dataField": "annual_interactions_per_demand_other",
                    "dataType": "number"
                },
                {
                    "caption": "Percent inbound interactions",
                    "dataField": "interactions_inbound",
                    "dataType": "number"
                },
                {
                    "caption": "Percent outbound interactions",
                    "dataField": "interactions_outbound",
                    "dataType": "number"
                },
                {
                    "caption": "Average CSAT score",
                    "dataField": "average_score_csat",
                    "dataType": "number"
                },
                {
                    "caption": "Average SLA compliance score",
                    "dataField": "average_score_sla_compliance",
                    "dataType": "number"
                }
            ]
        },


        {
            "caption": "Client Management", "columns": [
                {
                    "caption": "Average client tenure (years)",
                    "dataField": "average_client_tenure_years",
                    "dataType": "number"
                },
                {
                    "caption": "Average agents per team lead",
                    "dataField": "average_agents_per_team_lead",
                    "dataType": "number"
                },
                {
                    "caption": "Supervisor/team lead procedures",
                    "dataField": "lead_supervisor_process",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Training and Improvement", "columns": [
                {
                    "caption": "Average number of agents per trainer",
                    "dataField": "average_agents_per_trainer",
                    "dataType": "number"
                },
                {
                    "caption": "How long is your new agent training?",
                    "dataField": "new_agent_training_duration",
                    "dataType": "string"
                },
                {
                    "caption": "Dedicated trainers",
                    "dataField": "number_of_trainers",
                    "dataType": "number"
                },
                {
                    "caption": "Requirements for trainers",
                    "dataField": "trainer_requirements",
                    "dataType": "string"
                },
                {
                    "caption": "Training strategy",
                    "dataField": "training_strategy",
                    "dataType": "string"
                },
                {
                    "caption": "Training effectiveness measurements",
                    "dataField": "training_effectiveness",
                    "dataType": "string"
                },
                {
                    "caption": "Client training services",
                    "dataField": "training_services",
                    "dataType": "string"
                },
                {
                    "caption": "How identify knowledge/skills gaps",
                    "dataField": "training_gaps",
                    "dataType": "string"
                },
                {
                    "caption": "New agent training",
                    "dataField": "training_program",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Resiliency", "columns": [
                {
                    "caption": "IT center staff hours",
                    "dataField": "systems_redundancy_it_support",
                    "dataType": "string"
                },
                {
                    "caption": "People in systems department",
                    "dataField": "systems_redundancy_it_support_personnel",
                    "dataType": "number"
                },
                {
                    "caption": "Have business continuity plan?",
                    "dataField": "has_bcp",
                    "dataType": "boolean"
                },
                {
                    "caption": "Business continuity plan (if applicable)",
                    "dataField": "bcp_document",
                    "dataType": "string"
                },
                {
                    "caption": "Describe business continuity plan",
                    "dataField": "bcp_description",
                    "dataType": "string"
                },
                {
                    "caption": "Backup generator at each site?",
                    "dataField": "systems_redundancy_power",
                    "dataType": "boolean"
                },
                {
                    "caption": "Redundant internet and telecom?",
                    "dataField": "systems_redundancy_connectivity",
                    "dataType": "boolean"
                },
                {
                    "caption": "Systems redundancy/backup",
                    "dataField": "systems_redundancy",
                    "dataType": "string"
                },
                {
                    "caption": "Downtime past 12 months (days)",
                    "dataField": "bcp_downtime",
                    "dataType": "number"
                }
            ]
        },


        {
            "caption": "Social Responsibility & Impact Sourcing", "columns": [
                {
                    "caption": "Any Impact Program",
                    "dataField": "impact_any",
                    "dataType": "boolean"
                },
                {
                    "caption": "Woman-Owned: Certified Diverse",
                    "dataField": "cert_diverse_woman_owned",
                    "dataType": "string"
                },
                {
                    "caption": "Minority-Owned: Certified Diverse",
                    "dataField": "cert_diverse_minority_owned",
                    "dataType": "string"
                },
                {
                    "caption": "Disabled-Owned: Certified Diverse",
                    "dataField": "cert_diverse_disabled_owned",
                    "dataType": "string"
                },
                {
                    "caption": "Veteran-Owned: Certified Diverse",
                    "dataField": "cert_diverse_veteran_owned",
                    "dataType": "string"
                },
                {
                    "caption": "Other-Owned: Certified Diverse",
                    "dataField": "cert_diverse_other_owned",
                    "dataType": "string"
                },
                {
                    "caption": "Non-Cert Diverse: Faith-Owned",
                    "dataField": "noncert_diverse_faith_owned",
                    "dataType": "boolean"
                },
                {
                    "caption": "Non-Cert Diverse: Other",
                    "dataField": "noncert_diverse_other",
                    "dataType": "boolean"
                },
                {
                    "caption": "Econ Disadvantaged: program",
                    "dataField": "econ_disadvantaged_program",
                    "dataType": "boolean"
                },
                {
                    "caption": "Employment Disadvantaged: program",
                    "dataField": "employ_program",
                    "dataType": "boolean"
                },





                {
                    "caption": "Woman-Owned: Certification requirements",
                    "dataField": "cert_diverse_woman_owned_cert_req",
                    "dataType": "string"
                },
                {
                    "caption": "Woman-Owned: Certifying body ",
                    "dataField": "cert_diverse_woman_owned_cert_body",
                    "dataType": "string"
                },
                {
                    "caption": "Minority-Owned: certification requirements",
                    "dataField": "cert_diverse_minority_owned_cert_req",
                    "dataType": "string"
                },
                {
                    "caption": "Minority-Owned: Certifying body",
                    "dataField": "cert_diverse_minority_owned_cert_body",
                    "dataType": "string"
                },
                {
                    "caption": "Disabled-Owned: certification requirements",
                    "dataField": "cert_diverse_disabled_owned_cert_req",
                    "dataType": "string"
                },
                {
                    "caption": "Disabled-Owned: Certifying body",
                    "dataField": "cert_diverse_disabled_owned_cert_body",
                    "dataType": "string"
                },
                {
                    "caption": "Veteran-Owned: certification requirements",
                    "dataField": "cert_diverse_veteran_owned_cert_req",
                    "dataType": "string"
                },
                {
                    "caption": "Veteran-Owned: Certifying body",
                    "dataField": "cert_diverse_veteran_owned_cert_body",
                    "dataType": "string"
                },
                {
                    "caption": "Other-Owned: certification requirements",
                    "dataField": "cert_diverse_other_owned_cert_req",
                    "dataType": "string"
                },
                {
                    "caption": "Other-Owned: Certifying body",
                    "dataField": "cert_diverse_other_owned_cert_body",
                    "dataType": "string"
                },
                

                {
                    "caption": "Econ Disadvantaged: Location/Description",
                    "dataField": "econ_disadvantaged_locations",
                    "dataType": "string"
                },
                {
                    "caption": "Non-Cert Diverse: Faith-Owned Summary",
                    "dataField": "noncert_diverse_faith_owned_summary",
                    "dataType": "string"
                },
                {
                    "caption": "Non-Cert Diverse: Other Summary",
                    "dataField": "noncert_diverse_other_summary",
                    "dataType": "string"
                },
                {
                    "caption": "Econ Disadvantaged: Qualifications/certifications",
                    "dataField": "econ_disadvantaged_quals_and_certs",
                    "dataType": "string"
                },
                {
                    "caption": "Econ Disadvantaged: Polulations",
                    "dataField": "employ_programs_which_populations",
                    "dataType": "string"
                },
                {
                    "caption": "Econ Disadvantaged: details/clarifications",
                    "dataField": "employ_programs_which_populations_details",
                    "dataType": "string"
                },
                {
                    "caption": "Community give-back",
                    "dataField": "culture_give_back",
                    "dataType": "string"
                },
                {
                    "caption": "Charities supported",
                    "dataField": "charitable_organizations",
                    "dataType": "string"
                },
                {
                    "caption": "Company values",
                    "dataField": "company_values",
                    "dataType": "string"
                },
                {
                    "caption": "Mission/vision",
                    "dataField": "mission_vision",
                    "dataType": "string"
                },
                {
                    "caption": "Have CSR plan",
                    "dataField": "has_csr_plan",
                    "dataType": "boolean"
                },
                {
                    "caption": "CSR document",
                    "dataField": "csr_plan_attached",
                    "dataType": "File Upload"
                }
            ]
        },


        {
            "caption": "Systems Environment", "columns": [
                {
                    "caption": "Internal messaging",
                    "dataField": "internal_messaging_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal KBS",
                    "dataField": "internal_kb_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal LMS",
                    "dataField": "internal_lms_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal WFM",
                    "dataField": "internal_wfm_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal QA",
                    "dataField": "internal_qa_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal Voice",
                    "dataField": "internal_voice_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal IVR",
                    "dataField": "internal_ivr_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal CSAT",
                    "dataField": "internal_csat_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Internal forecasting",
                    "dataField": "internal_forecasting_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client messaging",
                    "dataField": "client_messaging_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client KBS",
                    "dataField": "client_kb_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client LMS",
                    "dataField": "client_lms_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client WFM",
                    "dataField": "client_wfm_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client QA",
                    "dataField": "client_qa_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client Voice",
                    "dataField": "client_voice_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client IVR",
                    "dataField": "client_ivr_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client CSAT",
                    "dataField": "client_csat_systems",
                    "dataType": "string"
                },
                {
                    "caption": "Client Forecasting",
                    "dataField": "client_forecasting_systems",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Account Management", "columns": [
                {
                    "caption": "lient services group managers",
                    "dataField": "account_management_personnel",
                    "dataType": "number"
                },
                {
                    "caption": "Accounts per account manager",
                    "dataField": "account_management_accounts_number",
                    "dataType": "string"
                },
                {
                    "caption": "Site visits allowed",
                    "dataField": "account_management_client_visitation",
                    "dataType": "boolean"
                },
                {
                    "caption": "Problem resolution process",
                    "dataField": "account_management_problem_resolution",
                    "dataType": "string"
                },
                {
                    "caption": "Change management process (Account)",
                    "dataField": "account_management_process_change",
                    "dataType": "string"
                },
                {
                    "caption": "Account management process",
                    "dataField": "account_management_processes",
                    "dataType": "string"
                }
            ]
        },


        {
            "caption": "Financials", "columns": [
                {
                    "caption": "Latest credit score",
                    "dataField": "latest_credit_score",
                    "dataType": "number"
                },
                {
                    "caption": "Experian pull date",
                    "dataField": "experian_pull_date",
                    "dataType": "string"
                }
            ]
        }



    ];

    return columnData;
}